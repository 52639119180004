<template>
  <section id="product_offers">
    <h2 class="s4 bold">
      {{ title }}
    </h2>
    <ul v-if="product.offres_group">
      <li
        v-for="(group, gIndex) in Object.keys(product.offres_group)"
        :key="`offre_group_${group.replace(' ', '_') || gIndex}`"
        class="list-offers"
      >
        <h3 class="s5 bold">
          {{ group }}
        </h3>
        <ul class="list-licences">
          <li
            v-for="(offer, index) in product.offres_group[group]"
            :id="`offre_${offer.id || index}`"
            :key="`offre_${offer.id || index}`"
          >
            <span class="circle" />
            <div class="zone-details text-regular">
              <div class="text-refs">
                <div class="bold">
                  <span>{{ offer.duree_verbose }}</span>
                  <span v-if="!compatibleAdoptant && product.categorie !== 21">
                    {{ offer.adoptant ? $t('offre.adoptant') : $t('offre.non-adoptant') }}
                  </span>
                </div>
                <span>{{ $t("produit.ref") }}{{ "\xa0" }}: {{ offer.ref_editeur }}</span>
                <span>{{ $t("produit.ref-lde") }}{{ "\xa0" }}: {{ offer.ean_lde }}</span>
              </div>
              <p
                v-if="!offer.livresenplus.length && offer.conditions_livraison"
                class="text-regular"
              >
                {{ offer.conditions_livraison }}
              </p>
              <template v-else>
                <p
                  v-for="lep in offer.livresenplus"
                  :key="lep.id"
                  class="text-regular"
                >
                  {{ lep.article_gratuit_verbose }}
                  {{
                    (lep.duree % 12) === 0
                      ? $tc("general.x-an", lep.duree / 12, { an: lep.duree / 12, })
                      : $t("general.x-mois", { mois: lep.duree, })
                  }}{{ "\xa0" }}:

                  <template v-if="lep.description">
                    {{ lep.description }}
                  </template>
                  <template v-else-if="lep.conditions[0]">
                    {{
                      $tc(
                        "produit.x-licence-offerte-des-x-article-commande",
                        lep.conditions[0].split(" pour ")[0],
                        {
                          licence: lep.conditions[0].split(" pour ")[0],
                          article: lep.conditions[0].split(" pour ")[1],
                        }
                      )
                    }}
                  </template>
                  <template v-if="lep.conditions.length > 1">
                    <br />
                    <ButtonClassic
                      data-bs-toggle="collapse"
                      data-bs-target="#conditions"
                      color="primary"
                      :label="lep.displayDetails ? $t('produit.masquer-conditions') : $t('produit.afficher-conditions')"
                      icon="left"
                      @click="$set(lep, 'displayDetails', !lep.displayDetails)"
                    >
                      <template #left-icon>
                        <UilSearchAlt />
                      </template>
                    </ButtonClassic>
                    <ul
                      id="conditions"
                      class="collapse"
                      :class="{ show: lep.displayDetails, }"
                    >
                      <li
                        v-for="cond in lep.conditions"
                        :key="cond"
                      >
                        {{ $t("produit.x-licence-achetee", { licence: cond, }) }}
                      </li>
                    </ul>
                  </template>
                </p>
              </template>
              <LicenceChoice
                v-model="product.offres_group[group][index]"
                :categorie="product.categorie"
                @add-to-liste="$emit('add-to-liste', offer)"
              />
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ManuelNumeriqueSupports
      :product="product"
      inline
    />
  </section>
</template>

<script>
import { ButtonClassic } from "@lde/core_lde_vue";

import ManuelNumeriqueSupports from "@/components/manuels_numeriques/ManuelNumeriqueSupports.vue";
import LicenceChoice from "@/components/products/LicenceChoice.vue";

import { UilSearchAlt } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche le détails des offres du produit.
 */
export default {
  name: "ManuelNumeriqueOffers",
  components: {
    ManuelNumeriqueSupports,
    LicenceChoice,
    ButtonClassic,
    UilSearchAlt,
  },
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
    /**
     * Titre de la section.
     */
    title: {
      type: String,
      default() { return this.$t("produit.detail-offres"); },
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton d'enregistrement.
     * @param {Object} offer Offre associée à la quantité.
     */
    "add-to-liste",
  ],
  computed: {
    ...mapGetters(["compatibleAdoptant"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/manuels_numeriques/manuel_numerique_offers.scss";
</style>
