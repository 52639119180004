var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"licence-choice"},[_c('div',{staticClass:"licence-content",on:{"mouseover":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('div',[_c('strong',{staticClass:"duration text-regular"},[_vm._v(" "+_vm._s(_vm.offer.duree_verbose)+" "),(!_vm.compatibleAdoptant && _vm.categorie !== 21)?[_vm._v(" "+_vm._s(_vm.offer.adoptant ? _vm.$t('offre.adoptant') : _vm.$t('offre.non-adoptant'))+" ")]:_vm._e(),(_vm.infoLink)?_c('a',{attrs:{"href":`#offre_${_vm.offer.id}`}},[_c('UilInfoCircle')],1):_vm._e()],2)]),(_vm.offer.prescripteur)?_c('div',{staticClass:"prices"},[_c('span',{staticClass:"text-medium bold"},[_vm._v(_vm._s(_vm.$t('offre.offre-offerte-editeur')))])]):[_c('div',{staticClass:"prices"},[(_vm.offer.prix_editeur !== _vm.offer.prix_ht)?_c('span',{staticClass:"text-medium bold all-taxes",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ttc', { prix: _vm.moneyFilter(_vm.offer.prix_editeur), }))}}):_vm._e(),_c('span',{staticClass:"text-medium duty-free",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ht', { prix: _vm.moneyFilter(_vm.offer.prix_ht), }))}})]),_c('InputQuantity',{attrs:{"status":_vm.offer.quantite < _vm.offer.quantite_min ? 'warning' : 'default',"shadow":_vm.hovered && _vm.isOfferAvailable(_vm.offer),"disabled":!_vm.isOfferAvailable(_vm.offer)},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('div',{staticClass:"select-btn-container"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('produit.article-n-est-pas-disponible'),
            disabled: _vm.isOfferAvailable(_vm.offer),
          }),expression:"{\n            content: $t('produit.article-n-est-pas-disponible'),\n            disabled: isOfferAvailable(offer),\n          }"}],staticClass:"zone-liste-panier"},[_c('ButtonClassic',{staticClass:"select-btn",attrs:{"variant":"special","label":_vm.$t('liste.ajouter-a-ma-liste'),"color":"primary","icon":"left","disabled":_vm.isLicenceChoiceDisabled},on:{"click":function($event){return _vm.$emit('add-to-liste')}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [(_vm.isFetchingActiveLists)?_c('InfiniteLoader'):_c('UilBookmark')]},proxy:true}])}),_c('ButtonClassic',{attrs:{"variant":"solid","label":_vm.$t('panier.au-panier'),"color":"secondary","icon":"right","size":"large","disabled":_vm.isImpersonating || !_vm.hasPerm('add_lignedeliste') || !_vm.isOfferAvailable(_vm.offer) || !_vm.quantity},on:{"click":function($event){return _vm.$store.dispatch(
                'addOffersToPanier',
                { lignes: [{ id_offre: _vm.offer.id, quantite: _vm.quantity, }, ], }
              )}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('IconPanier')]},proxy:true}])})],1)])]],2),((!_vm.quantity || _vm.quantity < _vm.offer.quantite_min) && _vm.offer.quantite_min > 0)?_c('p',{staticClass:"text-small italic minima-text flex-vcenter"},[_c('UisExclamationTriangle',{attrs:{"size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("produit.minima-a-x", { minima: _vm.offer.quantite_min, }))+" ")],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }